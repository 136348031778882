var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[(_vm.updateExists)?_c('div',{staticClass:"p-2 sticky-top update-banner"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-auto ms-auto my-auto"},[_c('button',{staticClass:"btn btn-sm btn-light fw-bold",on:{"click":_vm.refreshApp}},[_c('i',{staticClass:"far fa-redo-alt me-2"}),_vm._v("Click to update ")])])])])]):_vm._e(),(_vm.$store.impersonating)?_c('div',{staticClass:"p-1 sticky-top impersonating-banner"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col my-auto"},[_c('p',{staticClass:"text-white fw-bolder mb-0"},[_vm._v(" "+_vm._s(_vm.$store.impersonating.first_name)+", you are impersonating "+_vm._s(_vm.$store.user.name)+" ")])]),_c('div',{staticClass:"col-auto ms-auto my-auto"},[_c('a',{staticClass:"fw-bold text-white",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.stopImpersonating($event)}}},[_c('i',{staticClass:"far fa-user-secret me-2"}),_vm._v("End impersonation ")])])])])]):_vm._e(),(
      _vm.$router.currentRoute.fullPath === '/' ||
      _vm.$router.currentRoute.fullPath.includes('login')
    )?_c('front-end-nav',{attrs:{"path":_vm.$router.currentRoute.fullPath}}):_vm._e(),(
      _vm.$router.currentRoute.fullPath != '/events' &&
      _vm.$router.currentRoute.fullPath != '/' &&
      _vm.$router.currentRoute.fullPath != '/login'
    )?_c('main-nav'):_vm._e(),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('side-bar-nav'),_c('main',{staticClass:"ms-sm-auto px-md-4",class:_vm.user && _vm.$store.sidebarOpen ? 'col-md-9 col-lg-10' : 'col'},[_c('router-view',{staticClass:"py-4",attrs:{"id":"router-view"}})],1)],1)]),(_vm.user)?_c('snack-bar-alert'):_vm._e(),(_vm.user)?_c('notifications',{attrs:{"user":_vm.user}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col my-auto"},[_c('p',{staticClass:"text-white fw-bolder mb-0"},[_vm._v(" Important: There is an update available for the Amchara Staff Portal! To receive the latest updates click the button ")])])}]

export { render, staticRenderFns }