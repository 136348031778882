<template>
  <div
          class="modal fade"
          id="textEditorModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="textEditorModalTitle"
          aria-hidden="true"
  >
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="textEditorModalTitle">Document Editor</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row mb-3">
            <div class="col">
              <text-editor v-model="content" @input="updatedContent"></text-editor>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col mb-3">
              <button class="btn btn-outline-primary" @click="closeModal"><i class="fa fa-save me-2"></i>Save Changes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TextEditor } from "@jaythegeek/crmtoolkit";
export default {
  props: ["value"],
  data() {
    return {
      content: this.value
    };
  },
  methods: {
    updatedContent() {
      this.$emit("input", this.content);
    },
    closeModal() {
      $("#textEditorModal").modal("hide");
    }
  },
  mounted() {
    $("#textEditorModal").modal("show");

    $("#textEditorModal").on("hide.bs.modal", e => {
      this.$emit("close");
    });
  },
  components: {TextEditor}
};
</script>