<template>
  <div>
      <p class="alert alert-danger mb-0 mt-4" v-if="user.email_service === 'google' && user.mail_config.status === 'expired'">
          <i class="fad fa-exclamation-triangle me-1"></i>
          Your Google Email integration has expired. Please <a href="/oauth/gmail/7">click here to grant access to Swandoola</a> and continue using email integration.
      </p>
  </div>
</template>

<script>
export default {
  props: ["user"]
};
</script>

<style>
</style>