<template>
  <div
          class="modal fade"
          id="viewDocumentModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="viewDocumentModalTitle"
          aria-hidden="true"
  >
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="viewDocumentModalTitle">View Document</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row mb-3">
            <div class="col" v-html="document">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["document"],
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
    $("#viewDocumentModal").modal("show");

    $("#viewDocumentModal").on("hide.bs.modal", e => {
      this.$emit("close");
    });
  },
};
</script>