<template>
  <div>
    <div
      class="modal docked docked-right"
      id="modal-notifications"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header d-block h-auto py-2">
            <div class="btn-group">
              <button
                  class="btn btn-sm"
                  @click="viewBackgroundTasks = !viewBackgroundTasks"
                  :class="!viewBackgroundTasks ? 'btn-primary' : 'btn-outline-primary'"
              >
                <i class="fad fa-bell me-1"></i>
                Notifications
              </button>
              <button
                  class="btn btn-sm"
                  @click="viewBackgroundTasks = !viewBackgroundTasks"
                  :class="viewBackgroundTasks ? 'btn-primary' : 'btn-outline-primary'"
              >
                <i class="fad fa-user-clock me-1"></i>
                Background Tasks
              </button>
            </div>
            <div class="text-center d-block pt-1">
              <a href="#" @click="viewAll" class="small">
                View All
              </a>
            </div>
          </div>

          <notifications-view v-if="!viewBackgroundTasks" class="mt-2"></notifications-view>

          <background-tasks-view v-else></background-tasks-view>

          <!-- Modal Actions -->
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">
              {{ "Close" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackgroundTasksView from "./notifications-partials/BackgroundTasksView";
import NotificationsView from "./notifications-partials/NotificationsView";
export default {
  data() {
    return {
      viewBackgroundTasks: false,
    };
  },
  methods: {
    viewAll() {
      $('#modal-notifications').modal('hide');
      this.$router.push("/notifications");
    }
  },
  computed: {
    user() {
      return this.$store.user;
    },
  },
  components: {
    BackgroundTasksView,
    NotificationsView
  }
};
</script>
