<script>
export default {
  methods: {
    $can(permissionName) {
      if (this.$store.user && this.$store.user.user_permissions){
        return this.$store.user.user_permissions.includes(permissionName);
      } else {
        localStorage.removeItem("user");
        if (localStorage.getItem("googleLogin")) {
          var auth2 = gapi.auth2.getAuthInstance();
          auth2.signOut().then(function () {
            console.log("User signed out.");
          });
        }
        localStorage.removeItem("googleLogin");
        location.href = "/";
      }
    },
  },
};
</script>
