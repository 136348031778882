<template>
  <nav
    id="mainNav"
    class="navbar sticky-top navbar-expand-lg navbar-dark bg-success shadow-sm"
    v-if="user"
  >
    <div class="container-fluid">
      <router-link
        class="navbar-brand col-md-3 col-lg-2 me-0 px-3"
        :to="user ? '/dashboard' : '/'"
      >
        <img src="@/assets/images/white_logo.png" alt="Amchara" width="50" />

        <h5 class="d-inline ms-3 text-white mb-0">Amchara</h5>
      </router-link>

      <button
        class="btn btn-outline-light btn-sm"
        type="button"
        @click="$store.sidebarOpen = !$store.sidebarOpen"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="far fa-bars"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav w-100">
          <!-- <li class="nav-item ms-4" v-if="user">
            <router-link class="nav-link" to="/dashboard">
              Dashboard
            </router-link>
          </li>
          <li class="nav-item ms-4" v-if="user">
            <router-link class="nav-link" to="/enquiries">
              Enquiries
            </router-link>
          </li>
          <li class="nav-item ms-4" v-if="user">
            <router-link class="nav-link" to="/bookings">
              Bookings
            </router-link>
          </li>

          <li class="nav-item ms-4" v-if="user">
            <router-link class="nav-link" to="/consultations">
              Consultations
            </router-link>
          </li>

          <li class="nav-item ms-4" v-if="user">
            <router-link class="nav-link" to="/clients"> Clients </router-link>
          </li>-->

          <li
            class="nav-item ms-auto me-2 my-auto"
            v-if="$store.enquiriesSavedView"
          >
            <router-link
              class="btn btn-light btn-sm"
              :to="$store.enquiriesSavedView"
              >View saved enquiries list</router-link
            >
          </li>

          <li class="nav-item my-auto" v-if="$store.enquiriesSavedView">
            <button
              class="btn btn-light btn-sm"
              @click="$store.enquiriesSavedView = null"
            >
              <i class="far fa-times"></i>
            </button>
          </li>

          <!-- Search -->
          <form
            class="position-relative d-flex ms-auto me-4 w-50"
            @submit.prevent
          >
            <input
              class="form-control me-2"
              type="search"
              v-model="clientSearch"
              @input="searchForClient"
              placeholder="Search"
              aria-label="Search"
              v-if="$can('view client profiles')"
            />
            <div
              class="position-absolute end-0 bg-white shadow mt-5 w-100 me-2"
              style="
                z-index: 99999 !important;
                max-height: 600px;
                overflow: scroll;
              "
              v-if="$can('view client profiles')
                && clientSearch.length > 0 && clients.length > 0"
            >
              <ul class="list-group-flush">
                <li
                  class="list-group-item cursor-pointer"
                  v-for="c in clients"
                  :key="c.id"
                  @click="goToClient(c.id)"
                >
                  {{ c.name }} / {{ c.email }}
                </li>
              </ul>
            </div>
          </form>
          <!-- End Search -->

          <li class="nav-item my-auto me-3">
            <router-link
              to="/clients/create"
              class="nav-btn btn btn-light btn-sm"
            >
              <i class="far fa-plus me-2"></i>Add Client
            </router-link>
          </li>

          <!-- Dropdown -->
          <li class="nav-item dropstart">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdownMenuLink"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ user.name | acronym }}
            </a>
            <ul
              class="dropdown-menu mt-4"
              aria-labelledby="navbarDropdownMenuLink"
            >
              <li v-if="$can('manage users')">
                <router-link class="dropdown-item" to="/users"
                  >Manage Users</router-link
                >
              </li>
              <li v-if="$can('manage permissions') && $can('manage roles')">
                <router-link class="dropdown-item" to="/roles"
                  >Manage Roles & Permissions</router-link
                >
              </li>
              <li>
                <div class="dropdown-item" @click="logout()" v-if="user">
                  Logout
                </div>
              </li>
            </ul>
          </li>

          <button class="btn btn-light ms-4">
            <i class="far fa-bell me-2 text-success"></i
            ><span class="text-success fw-bold">12</span>
          </button>

          <!-- End Dropdown -->
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import debounce from "debounce";

export default {
  data() {
    return {
      taskCount: 0,
      eventCount: 0,
      enquiriesCount: 0,
      notificationsCount: 0,
      showSupport: false,
      clientSearch: "",
      clients: [],
    };
  },
  watch: {
    clientSearch: {
      handler(val, oldVal) {
        if (val == "") {
          this.clients = [];
        }
      },
    },
  },
  computed: {
    user() {
      return this.$store.user;
    },
    path() {
      return this.$router.currentRoute.fullPath;
    },
  },
  methods: {
    goToClient(id) {
      this.clients = [];
      this.clientSearch = "";
      this.$nextTick(() => {
        this.$router.push("/clients/" + id);
        // this.$router.go();
      });
    },
    searchForClient: debounce(function() {
      if (this.clientSearch.length >= 3) {
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/clients/search", {
            search: this.clientSearch,
          })
          .then(({ data }) => {
            this.clients = data;
          });
      }
    }, 300),
    logout() {
      localStorage.removeItem("user");
      if (localStorage.getItem("googleLogin")) {
        var auth2 = gapi.auth2.getAuthInstance();
        auth2.signOut().then(function () {
          console.log("User signed out.");
        });
      }
      localStorage.removeItem("googleLogin");
      location.href = "/";
    },
    fetchNotificationsCount() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/notifications/unread")
        .then(({ data }) => {
          this.notificationsCount = data.notifications.length;
        });
    },
    showNotifications() {
      this.$EventBus.$emit("showNotifications");
    },
  },
  mounted() {
    if (this.user) {
      // this.fetchTasksCount();
      // this.fetchEventsCount();
      // this.fetchEnquiriesCount();
      // this.fetchNotificationsCount();
    }

    this.$EventBus.$on("notificationsRead", function () {
      _this.notificationsCount = 0;
    });
  },
  filters: {
    acronym(name) {
      var str = name;
      var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
      var acronym = matches.join(""); // JSON

      return acronym;
    },
  },
};
</script>

<style lang="scss">
.navbar-light .navbar-nav .nav-link {
  color: #03006b;
}

.notification-pill {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 99px;
  width: 4.5rem;
  height: auto;
  background-color: #f4f6f6;
  color: #495a5a;
  font-weight: 600;
  font-size: 0.8rem;
}

.nav-icon-sized {
  font-size: 1.5rem;
}

.notification-pill {
  font-size: 0.75rem;
}

.notification-pill i {
  font-size: 1rem;
}
</style>
