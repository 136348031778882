<template>
  <div class="modal-body" v-else>
    <div class="notification-container" v-if="backgroundTasks.length === 0">
      <div v-if="loadingBackgroundTasks">
        <p class="text-center mt-3">
          <i class="fad fa-spin fa-spinner fa-2x"></i>
        </p>
      </div>
      <div v-else class="alert alert-warning">
        No background tasks found.
      </div>
    </div>

    <div class="notification-container bg-light mt-2" v-else>
      <div class="notification" v-for="task in backgroundTasks">
        <!-- Notification Icon -->
        <figure>

          <span class="fa-stack text-white fa-2x">
            <i class="fad fa-circle fa-stack-2x"></i>
            <i
                :class="[
                'fad',
                'fa-stack-1x',
                'fa-inverse',
                getIcon(task),
              ]"
            ></i>
          </span>
        </figure>

        <!-- Notification -->
        <div class="notification-content">
          <div class="meta">

            <div class="date text-dark">
              {{ task.created_at | relative }}
            </div>
          </div>

          <div class="notification-body">
            <p class="small">{{task.title}}</p>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loadingBackgroundTasks: true,
      backgroundTasks: []
    };
  },
  methods: {
    fetchBackgroundTasks() {
      this.$axios
          .get(process.env.VUE_APP_API_URL + "/background-tasks")
          .then(({ data }) => {
            this.backgroundTasks = data.data;
            this.loadingBackgroundTasks = false;
          });
    },
    getIcon(task) {
      if (task.finished) {
        return task.error ? 'fa-times-circle text-danger' : 'fa-check-circle text-success'
      } else {
        return 'fa-spinner-third fa-spin text-primary'
      }
    }
  },
  mounted() {
    this.fetchBackgroundTasks();
  },
  computed: {
    user() {
      return this.$store.user;
    },
  },
  filters: {
    relative(date) {
      return moment(date).local().locale("en-short").fromNow();
    },
  },
};
</script>
