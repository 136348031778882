<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
        <router-link class="navbar-brand" to="/">
          <img src="@/assets/images/site_logo.png" alt="Amchara" width="50" />
          <h5 class="fw-bold text-dark d-inline mb-0 ms-3">
            Amchara Staff Portal
          </h5>
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav w-100">
            <li class="nav-item ms-md-auto">
              <a class="nav-link text-dark" href="https://amchara.com" target="_blank"
                >Main Amchara Website</a
              >
            </li>
            <li class="nav-item">
              <router-link class="nav-link text-dark ms-4" to="/login">
                <i class="fad fa-sign-in me-2"></i>
                Staff Login
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>