<template>
  <vue-phone-number-input
          ref="widget"
          v-bind="$attrs"
          v-on="forwardListeners"
          v-model="nationalNumber"
          @update="onUpdate"
  />
</template>
<script>
  import VuePhoneNumberInput from 'vue-phone-number-input'
  import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js'

  export default {
    name: 'E164PhoneNumberInput',
    inheritAttrs: false,
    components: {
      VuePhoneNumberInput
    },
    props: ['value'],
    data () {
      return {
        countryCode: null,
        nationalNumber: null
      }
    },
    computed: {
      forwardListeners () {
        const { input, ...listeners } = this.$listeners;
        return listeners
      }
    },
    mounted() {
      this.setValue(this.value);
    },
    watch: {
      value (value) {
        this.setValue(value);
      },
      countryCode () {
        this.emitValue();
      },
      nationalNumber () {
        this.emitValue();
      }
    },
    methods: {
      onUpdate (data) {
        const asYouType = new AsYouType(data.countryCode);
        const nationalNumber = asYouType.input(data.phoneNumber);
        Object.assign(this, {
          nationalNumber,
          countryCode: data.countryCode
        })
      },
      setValue (value) {
        if (!this.$refs.widget) return;
        const phoneNumber = (value && parsePhoneNumberFromString(value)) || null;
        if (phoneNumber){
          console.log(phoneNumber);
          let nationalNumber = phoneNumber && phoneNumber.formatNational();
          const countryCode = (phoneNumber && phoneNumber.country) || this.countryCode;
          if (value) {
            // AsYouType gives us national number formatting
            const asYouType = new AsYouType(phoneNumber ? countryCode : undefined);
            nationalNumber = asYouType.input(nationalNumber || value);
            if (!phoneNumber) {
              // If phone number wasn't parsed, then AsYouType inputted entire number with country core prefix.
              // So we need to specifically get national number.
              nationalNumber = asYouType.getNumber().formatNational();
            }
          }

          Object.assign(this, {
            nationalNumber,
            countryCode: (phoneNumber && phoneNumber.country) || this.countryCode
          })
        }
      },
      emitValue () {
        const phoneNumber = this.nationalNumber && parsePhoneNumberFromString(this.nationalNumber, this.countryCode);
        let value = phoneNumber && phoneNumber.format('E.164');
        if (!value && this.nationalNumber) {
          const asYouType = new AsYouType(this.countryCode);
          asYouType.input(this.nationalNumber);
          value = asYouType.getNumber() && asYouType.getNumber().number;
        }
        if (value !== this.value) {
          this.$emit('input', value);
        }
        if (this.countryCode !== this.$refs.widget.countryCode) {
          this.$nextTick(() => {
            this.$refs.widget.countryCode = this.countryCode;
          })
        }
      }
    }
  }
</script>

<style lang="css">
  @import '~vue-phone-number-input/dist/vue-phone-number-input.css';
</style>
