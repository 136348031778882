<template>
  <div id="app">
    <div class="p-2 sticky-top update-banner" v-if="updateExists">
      <div class="container-fluid">
        <div class="row">
          <div class="col my-auto">
            <p class="text-white fw-bolder mb-0">
              Important: There is an update available for the Amchara Staff
              Portal! To receive the latest updates click the button
            </p>
          </div>
          <div class="col-auto ms-auto my-auto">
            <button @click="refreshApp" class="btn btn-sm btn-light fw-bold">
              <i class="far fa-redo-alt me-2"></i>Click to update
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="p-1 sticky-top impersonating-banner"
      v-if="$store.impersonating"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col my-auto">
            <p class="text-white fw-bolder mb-0">
              {{ $store.impersonating.first_name }}, you are impersonating
              {{ $store.user.name }}
            </p>
          </div>
          <div class="col-auto ms-auto my-auto">
            <a
              href="#"
              @click.prevent="stopImpersonating"
              class="fw-bold text-white"
            >
              <i class="far fa-user-secret me-2"></i>End impersonation
            </a>
          </div>
        </div>
      </div>
    </div>

    <front-end-nav
      v-if="
        $router.currentRoute.fullPath === '/' ||
        $router.currentRoute.fullPath.includes('login')
      "
      :path="$router.currentRoute.fullPath"
    ></front-end-nav>

    <main-nav
      v-if="
        $router.currentRoute.fullPath != '/events' &&
        $router.currentRoute.fullPath != '/' &&
        $router.currentRoute.fullPath != '/login'
      "
    ></main-nav>
    <div class="container-fluid">
      <div class="row">
        <side-bar-nav></side-bar-nav>

        <main
          :class="user && $store.sidebarOpen ? 'col-md-9 col-lg-10' : 'col'"
          class="ms-sm-auto px-md-4"
        >
          <router-view id="router-view" class="py-4" />
        </main>
      </div>
    </div>

    <snack-bar-alert v-if="user"></snack-bar-alert>

    <notifications v-if="user" :user="user"></notifications>
  </div>
</template>
<script>
import MainNav from "./views/layouts/MainNav";
import FrontEndNav from "./views/layouts/FrontEndNav";
import SnackBarAlert from "./views/layouts/SnackBarAlert";
import SideBarNav from "./views/layouts/SideBarNav";
import Notifications from "./views/layouts/Notifications";

export default {
  metaInfo: {
    title: "Amchara CRM",
    titleTemplate: "%s | Retreat stays in Gozo, Malta and Somerset, UK",
  },
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
    };
  },
  computed: {
    user() {
      return this.$store.user;
    },
  },
  methods: {
    refreshUserData() {
      console.log("Refreshing user data");
      this.$axios
        .get(
          process.env.VUE_APP_API_URL + "/practitioners-portal/refresh-user",
          {
            requester: "practitioners-portal",
          }
        )
        .then(({ data }) => {
          if (this.impersonating) {
            this.$store.impersonating = data.user;
            this.$store.impersonationToken = data.token;
          } else {
            this.$store.user = data.user;
            this.$store.token = data.token;
          }

          localStorage.setItem("user", JSON.stringify(data));
          if (data.impersonationToken) {
            this.$axios.defaults.headers.Authorization = `Bearer ${data.impersonationToken.access_token}`;
          } else {
            this.$axios.defaults.headers.Authorization = `Bearer ${data.token.access_token}`;
          }
        });
    },
    showRefreshUI(e) {
      this.registration = e.detail;
      this.updateExists = true;
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
    stopImpersonating() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/users/impersonate/stop")
        .then(({ data }) => {
          var switchBackUser = {
            user: this.$store.impersonating,
            token: this.$store.impersonationToken,
          };

          this.$store.impersonating = null;
          this.$store.impersonationToken = null;

          this.$store.user = switchBackUser.user;
          this.$store.token = switchBackUser.token;

          var storeUsers = {
            user: this.$store.user,
            token: this.$store.token,
            impersonating: null,
            impersonationToken: null,
          };

          localStorage.setItem("user", JSON.stringify(storeUsers));

          this.$axios.defaults.headers.Authorization = `Bearer ${this.$store.token.access_token}`;

          this.$axios.defaults.headers.common["amchara-impersonation"] = null;

          this.$router.push("/dashboard");
        });
    },
  },
  created() {
    // Checking for service worker updates

    document.addEventListener("applicationUpdated", this.showRefreshUI, {
      once: true,
    });

    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }

    // Refresh user data
    this.$EventBus.$on("refreshUser", this.refreshUserData);
  },
  components: {
    MainNav,
    SnackBarAlert,
    Notifications,
    FrontEndNav,
    SideBarNav,
  },
};
</script>
<style lang="scss">
.update-banner {
  background: rgb(255, 115, 0);
}

.impersonating-banner {
  background: black;
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  /* rtl:raw:
  right: 0;
  */
  bottom: 0;
  /* rtl:remove */
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 1.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}
</style>
