<template>
  <nav
    id="sidebarMenu"
    class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
    v-if="$store.user && $store.sidebarOpen"
  >
    <div class="position-sticky pt-5 mt-3">
      <ul class="nav flex-column">
        <li class="nav-item">
          <router-link class="nav-link" to="/dashboard">
            Dashboard
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            to="/clients"
            v-if="$can('view client data')"
          >
            Clients
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            to="/enquiries"
            v-if="$can('manage enquiries')"
          >
            Enquiries
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            to="/bookings/calendar"
            v-if="$can('view bookings')"
          >
            Bookings
          </router-link>
        </li>

        <li class="nav-item">
          <router-link class="nav-link" to="/bookings/create">
            Create Booking
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            to="/bookings/due-ins"
            v-if="$can('view bookings')"
          >
            Due-ins
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            to="/consultations"
            v-if="$can('view bookings')"
          >
            Consultations
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            to="/kitchen/board"
            v-if="$can('view kitchen')"
            >Kitchen</router-link
          >
        </li>

        <li class="nav-item">
          <router-link class="nav-link" to="/form-templates">Forms</router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            to="/reporting"
            v-if="$can('view reporting')"
          >
            Reporting
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            to="/posts"
            v-if="$can('manage blog posts')"
          >
            Blog
          </router-link>
        </li>

        <li class="nav-item">
          <router-link class="nav-link" to="/seo" v-if="$can('manage seo')">
            SEO
          </router-link>
        </li>

        <li class="nav-item" v-if="$can('manage users')">
          <router-link class="nav-link" to="/users"> Users </router-link>
        </li>

        <li class="nav-item" v-if="$can('manage roles')">
          <router-link class="nav-link" to="/roles"> Roles </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {};
</script>

<style>
</style>